<template>
    <div class="Error404">
        <div class="Error404__bg">
            <div class="Error404__wrapper">
                <div class="Error404__title">
                    {{ $lng.__('lk_webmaster_errors', 'Not Found') }} (#404)
                </div>
                <div class="Error404__message">
                    {{ $lng.__('lk_webmaster_errors', 'Page not found') }}

                </div>
                <div class="Error404__description">
                    {{ $lng.__('lk_webmaster_errors', 'The above error occurred while the Web server was processing your request. Please contact us if you think this is a server error. Thank you.') }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Error404',
    props: [],
    data() {
        return {

        };
    },
    watch: {},
    computed: {

    },
    methods: {

    },
    beforeMount() {

    }
};
</script>

<style lang="scss">

@import '../../../scss/utils/mixins.scss';
@import '../../../scss/utils/variables.scss';

.Error404{
    width: 100%;
    margin-top: 90px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    @include break(md) {
        margin-top: 0;
    }
    &__bg{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        max-width: 800px;
        border-radius: 10px;
        padding: 50px 30px;
        background: $color-day-error-light;
        text-align: center;
        @include break(md) {
            padding: 100px 50px;
        }
        @media (min-height: 1000px) {
            margin-top: 100px;
        }
        @media (min-height: 1200px) {
            margin-top: 200px;
        }
    }
    &__wrapper{
        max-width: 600px;
    }
    &__title{
        font-size: 24px;
        line-height: 120%;
        font-weight: bold;
        font-family: $font-primary;
        color: $color-day-error-dark;
        margin-bottom: 30px;
        @include break(md) {
            font-size: 32px;
        }
    }
    &__message{
        color: $color-day-error-dark;
        background: $color-day-error-bg;
        border-radius: 10px;
        padding: 22px;
        font-size: 20px;
        line-height: 28px;
        font-family: $font-secondary;
        font-weight: normal;
        margin-bottom: 25px;
        @include break(md) {
            font-size: 24px;
        }
    }
    &__description{
        font-family: $font-secondary;
        font-weight: normal;
        color: $color-text-default;
        font-size: 16px;
        line-height: 20px;
    }

}

</style>